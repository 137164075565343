import styled from '@emotion/styled';
import { Input } from 'antd';
import React, { useState } from 'react';
import Icon from './icon';
import { mq } from './_shared/media';
import { StyledH1 } from './_shared/styled-headings';
import { StyledSection } from './_shared/styled-section';

const StyledContactSection = styled(StyledSection)`
  margin-bottom: 70px;
`;
const StyledContainer = styled.section`
  display: flex;
  align-items: center;

  & > svg {
    font-size: 2rem;
    margin-right: 0.75rem;
  }
`;
const StyledFormContainer = styled.section`
  & > span {
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
const StyledForm = styled.div`
  color: var(--primary-color);

  font-weight: normal;
`;
const StyledTextSection = styled.section`
  white-space: pre-line;
  width: 100%;
  max-width: 500px;
  margin-left: 3px;
`;
const StyledSeparator = styled.div`
  height: 1px;
  width: 50%;
  background-color: var(--body-color);
`;
const StyledContacts = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  width: 100%;
  margin: 2rem 0;

  ${mq.gt.xs} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.gt.sm} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInputContainer = styled(Row)`
  jutify-content: flex-end;
  align-items: flex-start;
  margin: 16px 0;
`

const StyledInputBox = styled.textarea`
  width: 240px;
  resize: none;
  min-height: 30px;
  font-size: 18px;
`

const InputLabel = props => {
  return <StyledInputContainer>
    <Col><Row>{props.title} <span style={{color: "red", marginLeft: "2px"}}>*</span></Row></Col>
    <Col style={{ width: "20px", flexGrow: 1 }} />
    <Col>
      <StyledInputBox
        style={{ width: "240px" }}
        type={props.type}
        name={props.name} 
        rows={props.rows || 1}
        required/></Col>
  </StyledInputContainer>
}


const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`

const SubmitButton = styled.button`
  height: 40px;
  width: 300px;
  font-size: 16px;
  border: none;
  color: var(--title-color);
  border-radius: 4px;
  background-color: var(--secondary-color);
  &:hover {
    cursor: pointer;
    background-color: var(--primary-color);
  }
`

// TODO Integrate w/ Netlify
const ContactForm = () => {

  return <>
    <Col>
      <span style={{ textAlign: "center", marginBottom: "16px" }}>
        Or leave your details and I will reach out to you within a week!
      </span>
    </Col>
    <StyledContactForm
      name="contact"
      action="#"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <Col>
        <InputLabel
          title="Name "
          type="text"
          name="name"
        />
        <InputLabel
          title="Email "
          type="email"
          name="email"
        />
        <InputLabel
          title="Message "
          type="text"
          rows={5}
          name="message"
        />
        <Row style={{ flexGrow: 1, margin: "8px 0", justifyContent: "center" }}>
          <SubmitButton type="submit">Send</SubmitButton>
        </Row>
      </Col>
    </StyledContactForm>
  </>
}

const Contact = ({ data }) => {
  const {
    frontmatter: { phone, email, address },
    html,
  } = data;

  return (
    <React.Fragment>
      <StyledContactSection id="contact">
        <StyledH1>Contact Details</StyledH1>
        <StyledTextSection dangerouslySetInnerHTML={{ __html: html }} />
        <StyledSeparator />
        <StyledContacts>
          {address && (
            <StyledContainer>
              <Icon icon="building" />
              <StyledFormContainer>
                <StyledForm>Office Location</StyledForm>
                <span>{address}</span>
              </StyledFormContainer>
            </StyledContainer>
          )}
          {email && (
            <StyledContainer>
              <Icon icon="paper-plane" />
              <StyledFormContainer>
                <StyledForm>My Email Address</StyledForm>
                <a style={{ color: "white" }} href={'mailto:' + email}>{email}</a>
              </StyledFormContainer>
            </StyledContainer>
          )}
          {phone && (
            <StyledContainer>
              <Icon icon="mobile-alt" />
              <StyledFormContainer>
                <StyledForm>Phone Number</StyledForm>
                <span>{phone}</span>
              </StyledFormContainer>
            </StyledContainer>
          )}
        </StyledContacts>
        <StyledSeparator />
      </StyledContactSection>
      <ContactForm />
    </React.Fragment>
  );
};

export default Contact;
